import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Loader, MainContent } from '@/components/layout'
import { useAuthContext, useCurrentUser } from '@/hooks'
import { DEFAULT_AUTHENTICATED_PAGE } from '@/constants'

function Home() {
  const { session, isInitialLogIn: isFirstLogIn } = useAuthContext()

  const [isRedirecting, setIsRedirecting] = useState(false)

  const { shouldCompleteSignUp, isLoading: isLoadingUser } = useCurrentUser()

  const router = useRouter()

  // TODO: All login page logic should be moved here, next iteration

  useEffect(() => {
    if (!isFirstLogIn && !isRedirecting && !isLoadingUser) {
      setIsRedirecting(true)
      const path =
        !!session?.user && !shouldCompleteSignUp
          ? `/${DEFAULT_AUTHENTICATED_PAGE}`
          : `/login?_=${new Date().getTime()}`

      router.replace(path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    session,
    isFirstLogIn,
    isRedirecting,
    isLoadingUser,
    shouldCompleteSignUp,
  ])

  return (
    <MainContent>
      <Loader />
    </MainContent>
  )
}

export default Home
