import { cnConcat } from '@/utils'
import React, { PropsWithChildren } from 'react'

interface MainContentProps {
  isScrollable?: boolean
}

const MainContent = ({
  isScrollable = false,
  children,
}: PropsWithChildren<MainContentProps>) => {
  return (
    <main
      className={cnConcat(
        isScrollable ? 'overflow-y-auto' : 'overflow-hidden',
        'h-full min-h-0 flex-auto flex-col bg-default-50 border-l-tiny border-divider/15',
        'dark:bg-gray-950 dark:border-divider/10',
        'lg:my-2 lg:mr-2 lg:border-tiny lg:rounded-md lg:shadow-md dark:lg:shadow-none',
      )}
    >
      {children}
    </main>
  )
}

MainContent.displayName = 'MainContent'

export default MainContent
